import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Form, FormGroup, Button, Row, Col, Label, CustomInput, FormText, FormFeedback, Card, CardBody
} from 'reactstrap';

import { toggleModal } from 'core/ducks/ui/modal';
import { characterConverter } from 'core/model/lib';
import { getValidation, validate, getContent, clearMessages } from 'core/ducks/forms';
import { buildPath } from 'core/model/lib/urlTools';
import { Input, Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { PolygonInput } from 'input-fields';
import { UploadImage } from 'core/views/modals';
import { uploadData } from 'core/ducks/upload';
import { DropKML, DropKMLContextConsumer } from 'dropKML';
import { DynamicRoutes } from '../../model/routes';
import T from 'modules/i18n';

class Attribute extends Component {

	constructor(props) {
		super(props);

		this.initialValues = {
			name: '',
			mname: '',
			has_geometry: false,
			geom_type: '',
			max_geom: '',
			geom_color: '',
			allow_drag: false,
			allow_resize: false,
			polygon: [],
			icon: '',
			icon_alt: '',
			node: props.match.params.topic,
			has_predefined: false,
			predefined: []
		};

		this.state = {
			values: {
				...this.initialValues,
			},
			shortname: '',
			files: [],
			previewUrl: null,
			attribute: props.match.params.attribute ? props.match.params.attribute : null,
			project: props.match.params.project,
			underSubmit: false,
			submitted: false,
			httpStatus: 200,
			pending: true,
		};

		this.actions = bindActionCreators({toggleModal}, props.dispatch);

		this.fetchContent = this.fetchContent.bind(this);
		this.uploadData = this.uploadData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleShortnameChange = this.handleShortnameChange.bind(this);
		this.handleAddIcon = this.handleAddIcon.bind(this);
		this.getModalValues = this.getModalValues.bind(this);
		this.resetForm = this.resetForm.bind(this);
	}

	componentDidMount() {
		if (this.state.attribute) {
			this.fetchContent(this.state.attribute);
		} else {
			this.setState({pending: false});
		}
		this.props.dispatch( getValidation('attribute') );
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.attribute !== this.props.match.params.attribute) {
			const { attribute } = this.props.match.params;
			this.setState({
				attribute,
			});
			this.fetchContent(attribute);
		}

		if ( !prevState.underSubmit && this.state.underSubmit === 'post')
			this.uploadData('post');

		if ( !prevState.underSubmit && this.state.underSubmit === 'put')
			this.uploadData('put');

		if (!prevState.submitted && this.state.submitted)
			this.setState({
				submitted: false,
			});
	}

	fetchContent(attribute) {
		this.props.dispatch( getContent(`attribute/node/${this.state.values.node}/token/${attribute}`, 'attribute') )
		.then(status => {
			this.initialValues = Object.keys(this.initialValues)
				.reduce((obj, key) => ({
					...obj,
					[key]: this.props.content.attribute[key]
				}), {});

			this.setState({
				values: {...this.initialValues},
				shortname: this.initialValues.mname,
				pending: false,
			});
		})
		.catch(status => {
			this.setState({httpStatus: status, pending: false});
		});
	}

	uploadData(method) {
		let formData = new FormData();
		const { values } = this.state;
		let empty = true;
		Object.keys(values).forEach(key => {
			if (key !== 'icon' && key !== 'predefined') {
				if (method === 'put') {
					if (this.initialValues[key] !== values[key]) {
						empty = false;
						if (key === 'polygon') {
							formData.append(key, JSON.stringify(values[key]));
						} else {
							formData.append(key, values[key]);
						}
					}
				} else {
					if (values[key] !== '')
						if (key === 'polygon') {
							formData.append(key, JSON.stringify(values[key]));
						} else {
							formData.append(key, values[key]);
						}
				}
			}
		});
		if (this.state.files[0]) {
			empty = false;
			formData.append('icon', this.state.files[0]);
		}

		if (!empty || method === 'post') {
			this.setState({showProgress: true}, () => {
				if (method === 'put')
					formData.append('_method', 'put');
				const url = method === 'put' ? `attribute/token/${this.state.attribute}` : 'attribute';
				this.props.dispatch( uploadData(url, formData) )
					.then(response => {
						this.initialValues = {
							...this.state.values,
						};
						this.setState({underSubmit: false});
						if (typeof this.props.toggle === 'function')
							this.props.toggle();
						if (method === 'post')
							this.props.history.push(buildPath(DynamicRoutes.AttributeEdit, [this.state.project, values.node, response.token]));
					})
					.catch(error => {
						this.setState({underSubmit: false});
						console.warn(error);
					});
			});
		} else {
			this.setState({underSubmit: false});
		}
	}

	handleChange(event) {
		let { name, type, value, checked } = event.target;
		let otherValues = {};
		if ((name === 'has_geometry') && checked === false)
			otherValues = {
				polygon: [],
				geom_type: '',
				max_geom: '',
			};
		this.setState({
			values: {
				...this.state.values,
				...otherValues,
				[name]: (type === 'checkbox' || type === 'switch') ? checked : value
			},
			underSubmit: false
		});
	}

	handleShortnameChange(event) {
		const { value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages.mname !== '')
			dispatch( clearMessages('mname') );
		this.setState({
			shortname: value,
			values: {
				...this.state.values,
				mname: characterConverter(value),
			}
		});
	}

	handleAddIcon() {
		this.actions.toggleModal(true,
			<UploadImage
				getValues={this.getModalValues}
				toggle={() => {this.actions.toggleModal()}}
				values={{file: this.state.values.icon, alt: this.state.values.icon_alt}}
			/>
		);
	}

	getModalValues(values) {
		const { file, alt, files } = values;
		this.setState({
			values: {
				...this.state.values,
				icon: file,
				icon_alt: alt,
			},
			files,
			previewUrl: files[0] ? URL.createObjectURL(files[0]) : null,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		const method = this.state.attribute ? 'put' : 'post';
		dispatch(validate(this.state.values, rules, 'node', this.initialValues)).then(() => {
			if (this.props.valid)
				this.setState({underSubmit: method});
		});
	}

	resetForm() {
		this.setState({
			values: {...this.initialValues},
			shortname: this.initialValues.mname,
			files: [],
			previewUrl: null,
		});
	}

	render() {

		const { values, httpStatus, pending } = this.state;
		const { rules, messages } = this.props;

		if (this.props.validationPending || this.props.validationScope !== 'attribute' || pending)
			return (<Loading />);

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus} />);

		return (
			<Card className="ppcity-admin">
				<CardBody>
					<Form onSubmit={this.handleSubmit}>
						<Row>
							<Col>
								<FormGroup row>
									<Col className="py-0" sm="8" lg="9">
										<FormGroup>
											<Input
												style={{fontSize: 150+'%'}}
												placeholder="Name"
												name="name"
												value={values.name}
												onChange={this.handleChange}
												autoComplete="off"
												pattern={rules.name.validation}
												valid={messages.name === ''}
											/>
											<FormFeedback><T>{messages.name || rules.name.message}</T></FormFeedback>
										</FormGroup>
									</Col>
									<Col className="py-1" sm="3" lg="2" style={{margin: '-1.2rem 0 0 0'}}>
										<FormGroup>
											<Label
												style={{opacity: this.state.shortname==='' ? 0 : 1, fontSize: "75%"}}
												className="animated fadeIn fadeOut my-0"
											>
												Short name
											</Label>
											<Input
												style={{fontSize: 75+'%'}}
												onChange={this.handleShortnameChange}
												value={this.state.shortname}
												placeholder="Short name"
												className="d-inline-block"
												name="shortname"
												autoComplete="off"
												minLength={rules.mname.min_size}
												maxLength={rules.mname.max_size}
												valid={messages.mname === ''}
											/>
											<FormFeedback><T>{messages.mname || rules.mname.message}</T></FormFeedback>
										</FormGroup>
									</Col>
									<Col sm="1" style={{padding: 0, margin: 'auto', textAlign: 'center'}}>
										<span className="icon-box" title="Click to add an icon." onClick={this.handleAddIcon}>
											{ (this.state.previewUrl || values.icon) ?
												<img
													style={{width: '100%', maxWidth: '100%', height: 'auto', maxHeight: '100%'}}
													src={this.state.previewUrl || values.icon}
													alt="icon"
												/>
												:
												<span className="text">icon</span>
											}
										</span>
									</Col>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="float-right">
									<FormGroup>
										<CustomInput
											id="has_geometry_switch"
											type="switch"
											label="Has geometry?"
											name="has_geometry"
											checked={values.has_geometry}
											onChange={this.handleChange}
										/>
									</FormGroup>
									<fieldset disabled={!values.has_geometry} className="border-0">
										<FormGroup style={{marginTop: '-1.2rem'}}>
											<Label
												style={{opacity: values.geom_type==='' ? 0 : 1, fontSize: "75%"}}
												className="animated fadeIn fadeOut my-0"
											>
												Geometry type:
											</Label>
											<Input
												type="select"
												name="geom_type"
												value={values.geom_type}
												onChange={this.handleChange}
											>
												<option value=''>Select geometry type</option>
												<option value="point">Point</option>
												<option value="line">Line</option>
												<option value="polygon">Polygon</option>
											</Input>
										</FormGroup>
										<FormGroup style={{marginTop: '-1.2rem'}}>
											<Label
												style={{opacity: values.max_geom==='' ? 0 : 1, fontSize: "75%"}}
												className="animated fadeIn fadeOut my-0"
											>
												Number of geometries:
											</Label>
											<Input
												placeholder="Geometries number (max)"
												type="number"
												name="max_geom"
												value={values.max_geom}
												onChange={this.handleChange}
											/>
										</FormGroup>
										<FormGroup style={{marginTop: '-1.2rem'}}>
											<Label style={{fontSize: "75%"}}>Geometry color:</Label>
											<Input
												placeholder="Geometry color"
												type="color"
												name="geom_color"
												value={values.geom_color}
												onChange={this.handleChange}
											/>
										</FormGroup>
										<FormGroup>
											<CustomInput
												id="has_predefined_switch"
												type="switch"
												label="Has predefined geometries?"
												name="has_predefined"
												checked={values.has_predefined}
												onChange={(event) => {
													this.setState({
														values: {
															...this.state.values,
															has_predefined: event.target.checked,
														}
													});
												}}
											/>
										</FormGroup>
										<fieldset disabled={!values.has_predefined} className="border-0">
											<FormGroup check >
												<Label check >
													<Input
														type="checkbox"
														name="allow_drag"
														value={values.allow_drag}
														checked={values.allow_drag}
														onChange={this.handleChange}
													/>
													{' '}
													Allow drag
												</Label>
											</FormGroup>
											<FormGroup check >
												<Label check >
													<Input
														type="checkbox"
														name="allow_resize"
														value={values.allow_resize}
														checked={values.allow_resize}
														onChange={this.handleChange}
													/>
													{' '}
													Allow resize
												</Label>
											</FormGroup>
										</fieldset>
									</fieldset>
								</div>
								<FormGroup>
									<FormText>
										{values.has_predefined ? 'Draw the predefined geometries or drop a KML.' : 'Only for predefined geometries.'}
									</FormText>
									<DropKML disabled={!values.has_predefined}>
										<DropKMLContextConsumer>
											{({kml}) =>
												<PolygonInput
													draw={{
														polygon: {showArea: true, metric: ['km', 'm'], precision: {km: 2, m: 1}},
														rectangle: true,
														marker: true,
														polyline: true,
														circlemarker: false,
														circle: false
													}}
													center={this.props.mapSettings.center || [37.983810, 23.727539]}
													zoom={this.props.mapSettings.zoom || 14}
													height={500}
													onChange={this.handleChange}
													name="polygon"
													geojson={kml ? kml : (values.predefined && values.predefined.features && values.predefined.features.length > 0) ? values.predefined : null}
													returnGeoJSON={true}
													disabled={!values.has_predefined}
												/>
											}
										</DropKMLContextConsumer>
									</DropKML>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="text-right">
								<Button className="mr-2" type="submit" color="primary">Add</Button>
								{ typeof this.props.toggle === 'function' &&
									<Button type="button" color="warning" onClick={this.props.toggle}>Cancel</Button>
								}
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	contentPending: state.forms.pending,
	content: state.forms.content,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
	messages: state.forms.validation_msgs,
	progress: state.upload.progress,
	mapSettings: state.ui.settings.values.map,
});

Attribute = connect(mapStateToProps)(Attribute);

export default Attribute;
